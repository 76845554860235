import funnyIcon  from "../static/categoriesimgs/funny-icon.svg"
import sportsIcon  from "../static/categoriesimgs/sports-icon.svg"
import musicIcon  from "../static/categoriesimgs/music-icon.svg"
import televisonIcon  from "../static/categoriesimgs/televison-icon.svg"
import scifisIcon  from "../static/categoriesimgs/sci-fi-icon.svg"
//import vintageIcon  from "/home/user/podokan/frontend/src/static/categoriesimgs/vintage-icon.svg"
import animeIcon  from "../static/categoriesimgs/anime-icon.svg"
import shirtIcon  from "../static/categoriesimgs/shirts-icon.svg"
import hoodieIcon  from "../static/categoriesimgs/hoodie-icon.svg"
//import hatIcon  from "/home/user/podokan/frontend/src/static/categoriesimgs/hats-icon.svg"
import movieIcon  from "../static/categoriesimgs/movies-icon.svg"
//import tanktopsIcon from "/home/user/podokan/frontend/src/static/categoriesimgs/tanktop-icon.svg"
//import stickerIcon  from "/home/user/podokan/frontend/src/static/categoriesimgs/sticker-icon.svg"
//import kidshirtIcon  from "/home/user/podokan/frontend/src/static/categoriesimgs/kids-tshirt-icon.svg"




// navigation Data
export const navItems = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "Best Selling",
    url: "/best-selling",
  },
  {
    title: "Products",
    url: "/products",
  },
  // {
  //   title: "Events",
  //   url: "/events",
  // },
  {
    title: "FAQ",
    url: "/faq",
  },
];

// branding data
export const brandingData = [
  {
    // Add branding data if needed
  },
];

// categories data
export const categoriesData = [
  {
    id: 1,
    title: "funny",
    icon: `${funnyIcon}`,
  },
  {
    id: 2,
    title: "music",
    icon: `${musicIcon}`,
  },
  {
    id: 3,
    title: "anime",
    icon: `${animeIcon}`,
  },
  {
    id: 4,
    title: "movies",
    icon: `${movieIcon}`,
  },
  {
    id: 5,
    title: "television",
    icon:`${televisonIcon}`
  },
  {
    id: 6,
    title: "sports",
    icon: `${sportsIcon}`
  },
  {
    id: 7,
    title: "sci-fi",
    icon:`${scifisIcon}`
  },
 //{
  //  id: 8,
 //   title: "Vintage",
 //   icon: `${vintageIcon}`
 // },
  {
    id: 9,
    title: "Hoodies",
    icon: `${hoodieIcon}`,
  },
  {
    id: 10,
    title: "t-shirts",
    icon: `${shirtIcon}`,

  },
  //{ id: 11,  title: "Hats",  icon:  `${hatIcon}`,},
 // {
   // id: 12,
 //   title: "Tank Tops",
   // icon:   `${tanktopsIcon}`,
  //},
 // {
  //  id: 13,
  //  title: "Stickers",
 //   icon:   `${stickerIcon}`,
 // },
 // {
  //  id: 14,
  //  title: "Kids T-shirts",
  //  icon:   `${kidshirtIcon}`,
 // },
];
  
  export const productdata =[
    {
    user:{

    },
    userRating:{

    } 
  },
  ]
  export const footerProductLinks = [
    {
      name: "About us",
      link: "/about"
    },
    {
      name: "Careers",
      link: "/carrers"
    },
    {
      name: "Store Locations",
    },
    {
      name: "Our Blog",
    },
    {
      name: "Reviews",
    },
  ];
  
  export const footercompanyLinks = [
    {
      name: "Game & Video",
    },
    {
      name: "Phone &Tablets",
    },
    {
      name: "Computers & Laptop",
    },
    {
      name: "Sport Watches",
    },
    {
      name: "Events",
    },
  ];
  
  export const footerSupportLinks = [
    {
      name: "FAQ",
    },
    {
      name: "Reviews",
    },
    {
      name: "Contact Us",
    },
    {
      name: "Shipping",
    },
    {
      name: "Live chat",
    },
  ];
  